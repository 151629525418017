import 'aframe';
import 'aframe-particle-system-component';
import 'aframe-aabb-collider-component';
import {Entity} from 'aframe-react';
import React from 'react';
import vacaGlb from './glb/vaca0a.glb';

class Cow extends React.Component {
  constructor (props) {
    super(props);
  }
  static defaultProps = {
    name: "",
    id: 0
  }

  render () {
    return (
      <Entity {...this.props} cow="" gltf-model={vacaGlb} aabb-collider={{collideNonVisible: false, objects: ''}} material={{src: '#cow2', roughness: 0.9}}>
        <a-text position="0 0.07 0.05" rotation= "0 0 0" color="#FFFFFF" font="dejavu" align="center" width="0.2" value={this.props.name}></a-text>
        <a-text position="0 0.07 0.05" rotation= "0 180 0" color="#FFFFFF" font="dejavu" align="center" width="0.2" value={this.props.name}></a-text>
        {this.props.children}
      </Entity>
    );
  }
}

export default Cow;
