import 'aframe';
import {Entity} from 'aframe-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Cow from './Cow';

class Player extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      id: 0,
      name: "Player"
    }
  }

  render () {
    return (
      <Cow id="vacaX" player="" name={this.state.name}>
        {this.props.children}
        <Entity position="0 0.1 -0.1" rotation="0 180 0">
          <a-entity id="light" light="type: spot; distance: 5; penumbra: 1; angle: 120; target: #vacaX"></a-entity>
          <a-camera id="camera" position="0 0 0" user-height="0" wasd-controls-enabled="false" look-controls-enabled="true"></a-camera>
        </Entity>
      </Cow>
    );
  }
}

export default Player;
