import grass from './img/grass.jpg';
import stars from './img/stars.jpg';
import vaca from './img/vacabc2.png';

function Assets () {
  return (
      <a-assets timeout="36000">
        <img id="grass" src={grass} width="2048" height="2048"/>
        <img id="stars" src={stars}/>
        <img id="cow2" src={vaca}/>
      </a-assets>
  );
}

export default Assets;
