import 'aframe';
import 'aframe-extras';
import 'aframe-particle-system-component';
import './components/terrain';
import './components/cow';
import './components/player';
import {Entity, Scene} from 'aframe-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Assets from './Assets';
import Player from './Player';
import './App.css';

class App extends React.Component {
  render () {
    return (
      <Scene terrain="">
        <Assets />
        <Player />
        <a-sky src="#stars"/>
      </Scene>
    );
  }
}

export default App;
